import { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Skeleton from "@mui/material/Skeleton";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import GridOnIcon from '@mui/icons-material/GridOn';
import { CSVDownload } from "react-csv";

export default function DataList() {
    const updateIsBcaData = useAppStore((state) => state.updateIsBcaData);
    const isBcaData = useAppStore((state) => state.isBcaData);
    const baseURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    // const updateBca = useAppStore((state) => state.updateBca);
    // const bcastore = useAppStore((state) => state.bca);
    const token = useAppStore((state) => state.token);
    const utype = useAppStore((state) => state.utype);
    const navigate = useNavigate();
    const [isDownload, setIsDownload] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [bca, setBca] = useState(isBcaData);
    const [lastPage, setLastPage] = useState(1);
    const [disabled, setDisabled] = useState(false);
    const abortControllerRef = useRef(null);


    const handleFilter = () => {
        // console.log('Filtered Start Date', startDate);
        // console.log('Filtered End Date', endDate);

        // Ensure startDate and endDate are valid Date objects or strings in 'YYYY-MM-DD' format
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (isNaN(start.getTime()) || isNaN(end.getTime())) {
            console.error('Invalid start or end date');
            return;
        }

        // Adjust end date to include the entire day
        end.setHours(23, 59, 59, 999);

        const filtered = isBcaData.filter(item => {
            const createdAt = new Date(item.created_at);
            return createdAt >= start && createdAt <= end;
        });

        // console.log('Filtered data', filtered);

        setBca(filtered);
    };

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
    }, []);

    useEffect(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        getUsersCall();
    }, []);


    const getUsersCall = () => {
        if (utype === "Admin" || utype === "Project Head") {
            console.log('getUsersCall Admin ');
            const posturl = `${baseURL}bca`;
            getUsers(posturl);
        } else if (utype === "Area Coordinator" || utype === "District Coordinator") {
            const posturl = `${baseURL}onlybcareport`;
            console.log('getUsersCall District');
            getUsers(posturl);
        }
        else if (utype === "BC Agent") {
            let posturl = baseURL + "mybcareport";
            console.log('getUsersCall Agent');
            getUsers(posturl);
        }
    };

    const getPaginatedUsers = async () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        setDisabled(true);
        const accumulatedData = [];

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        if (lastPage > 1) {
            if (utype === "Admin" || utype === "Project Head") {
                const fetchPageData = async (page) => {
                    const posturl = `${baseURL}bca?page=${page}`;
                    try {
                        const res = await axios.get(posturl, {
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-type": "Application/json",
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        console.log('bcadata ', res.data);
                        return res.data;
                    } catch (err) {
                        console.log("kcheckpost" + err); // return 429
                        return null;
                    }
                };

                abortControllerRef.current = new AbortController();
                const signal = abortControllerRef.current.signal;

                for (let i = 2; i <= lastPage; i++) {
                    await delay(1000); // Wait for 1 second between requests
                    if (signal.aborted) {
                        break; // Exit loop if the request is canceled
                    }
                    const pageData = await fetchPageData(i);
                    if (pageData) {
                        accumulatedData.push(...pageData.data);
                    }
                }
                setDisabled(false);
                // Update the state after collecting all data
                setBca(accumulatedData);
                updateIsBcaData(accumulatedData);

            }
        }

    };


    const getUsers = async (posturl) => {
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log('bcadata ', res.data);
                // updateBca(res.data);
                // setBca(res.data.data);
                // updateIsBcaData(res.data.data);
                setBca(res.data.data || []); 
                updateIsBcaData(res.data.data || []);
                let last_Page = res.data.last_page;
                setLastPage(last_Page)
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                deleteApi(id);
            }
        });
    };

    const deleteApi = async (id) => {
        let posturl = baseURL + "bca/" + id;
        await axios
            .delete(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (res) {
                console.log(res.data);
                getUsersCall();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const filterData = (v) => {
        if (v) {
            setBca([v]);
        } else {
            setBca(isBcaData);
        }
    };

    const editData = (
        id,
        user_id,
        koid,
        name,
        district_coordinator,
        no_of_withdrawal,
        total_withdrawal_amount,
        no_of_deposite,
        total_deposite_amount,
        no_of_fund_transfer,
        total_transfer_amount,
        no_of_account_open,
        recurring_deposite,
        fixed_deposite,
        fixed_deposite_amt,
        recurring_deposite_amt,
        shg_withdrawal,
        shg_withdrawal_amt,
        shg_deposite,
        shg_deposite_amt,
        apy,
        pmsby,
        pmjjby,
        district,

    ) => {
        const data = {
            id: id,
            user_id: user_id,
            koid: koid,
            name: name,
            district_coordinator: district_coordinator,
            no_of_withdrawal: no_of_withdrawal,
            total_withdrawal_amount: total_withdrawal_amount,
            no_of_deposite: no_of_deposite,
            total_deposite_amount: total_deposite_amount,
            no_of_fund_transfer: no_of_fund_transfer,
            total_transfer_amount: total_transfer_amount,
            no_of_account_open: no_of_account_open,
            recurring_deposite: recurring_deposite,
            fixed_deposite: fixed_deposite,
            fixed_deposite_amt: fixed_deposite_amt,
            recurring_deposite_amt: recurring_deposite_amt,
            shg_withdrawal: shg_withdrawal,
            shg_withdrawal_amt: shg_withdrawal_amt,
            shg_deposite: shg_deposite,
            shg_deposite_amt: shg_deposite_amt,
            apy: apy,
            pmsby: pmsby,
            pmjjby: pmjjby,
            district: district,

        };
        navigate("/edit-bca", {
            state: {
                id: id,
                user_id: user_id,
                koid: koid,
                name: name,
                district_coordinator: district_coordinator,
                no_of_withdrawal: no_of_withdrawal,
                total_withdrawal_amount: total_withdrawal_amount,
                no_of_deposite: no_of_deposite,
                total_deposite_amount: total_deposite_amount,
                no_of_fund_transfer: no_of_fund_transfer,
                total_transfer_amount: total_transfer_amount,
                no_of_account_open: no_of_account_open,
                recurring_deposite: recurring_deposite,
                fixed_deposite: fixed_deposite,
                fixed_deposite_amt: fixed_deposite_amt,
                recurring_deposite_amt: recurring_deposite_amt,
                shg_withdrawal: shg_withdrawal,
                shg_withdrawal_amt: shg_withdrawal_amt,
                shg_deposite: shg_deposite,
                shg_deposite_amt: shg_deposite_amt,
                apy: apy,
                pmsby: pmsby,
                pmjjby: pmjjby,
                district: district,
            },
        });
    };

    const handleExport = () => {
        setIsDownload(true);
    };

    useEffect(() => {
        if (isDownload) {
            setIsDownload(false);
        }
    }, [isDownload]);

    return (
        <>
            {isDownload && (
                <>
                    <CSVDownload data={bca} target="_blank" />;
                </>
            )}
            <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                <div style={{ display: "flex" }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ padding: "20px" }}
                    >
                        Daily BCA Report
                    </Typography>
                </div>

                <Divider />
                <Box height={10} />
                <Stack direction="row" spacing={2} className="my-2 mb-2">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={bca}
                        sx={{ width: 300 }}
                        onChange={(e, v) => filterData(v)}
                        getOptionLabel={(bca) => bca.koid || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label="Search"
                            />
                        )}
                    />

                    <div className="flex justify-center">
                        <div className="mr-7 ml-4">
                            <label className="block text-gray-700 text-sm font-bold">Start Date:</label>
                            <input
                                type="date"
                                className="border rounded w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>

                        <div className="mr-7">
                            <label className="block text-gray-700 text-sm font-bold">End Date:</label>
                            <input
                                type="date"
                                className="border rounded w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>

                        <button
                            style={{ minWidth: "100px", maxHeight: "40px" }}
                            className="bg-blue-500 mt-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handleFilter}
                        >
                            Filter
                        </button>
                    </div>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    <Button
                        variant="contained"
                        endIcon={<GridOnIcon />}
                        style={{ minWidth: "100px", maxHeight: "40px", marginTop: "10px" }}
                        onClick={handleExport}
                    >
                        Export
                    </Button>
                    {(utype === "Admin" || utype === "Project Head") && (
                        <>
                            {disabled ? (
                                <Button
                                    variant="contained"
                                    disabled
                                    style={{ minWidth: "100px", maxHeight: "40px", marginTop: "10px" }}
                                >
                                    Fetch all records
                                    <span className="mb-1 -mr-4 ml-3">
                                        <svg ariaHidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                </Button>
                            ) :
                                (
                                    <Button
                                        variant="contained"
                                        style={{ minWidth: "100px", maxHeight: "40px", marginTop: "10px" }}
                                        onClick={() => {
                                            getPaginatedUsers();
                                        }}
                                    >
                                        Fetch all records
                                    </Button>
                                )
                            }
                        </>
                    )}
                    {utype == "BC Agent" && (
                        <>
                            <Button
                                variant="contained"
                                endIcon={<AddCircleIcon />}
                                style={{ minWidth: "100px", maxHeight: "40px", marginTop: "10px" }}
                                onClick={() => {
                                    navigate("/add-bca");
                                }}
                            >
                                Add
                            </Button>
                        </>
                    )}
                </Stack>
                <Box height={10} />
                {bca.length > 0 && (
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        KOID
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        No. of Withdrawal
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Total Withdrawal Amt.
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        No. of Deposite
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Total Deposite Amt.
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        No. of Fund Transfer
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Total Transfer Amt.
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        No. of Account Open
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Created At
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bca
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell align="left">
                                                    {row.koid}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.no_of_withdrawal}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.total_withdrawal_amount}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.no_of_deposite}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.total_deposite_amount}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.no_of_fund_transfer}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.total_transfer_amount}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.no_of_account_open}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {moment(
                                                        row.created_at
                                                    ).format("MMMM Do YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack
                                                        spacing={2}
                                                        direction="row"
                                                    >
                                                        <EditIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "blue",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                editData(
                                                                    row.id,
                                                                    row.user_id,
                                                                    row.koid,
                                                                    row.name,
                                                                    row.district_coordinator,
                                                                    row.no_of_withdrawal,
                                                                    row.total_withdrawal_amount,
                                                                    row.no_of_deposite,
                                                                    row.total_deposite_amount,
                                                                    row.no_of_fund_transfer,
                                                                    row.total_transfer_amount,
                                                                    row.no_of_account_open,
                                                                    row.recurring_deposite,
                                                                    row.fixed_deposite,
                                                                    row.fixed_deposite_amt,
                                                                    row.recurring_deposite_amt,
                                                                    row.shg_withdrawal,
                                                                    row.shg_withdrawal_amt,
                                                                    row.shg_deposite,
                                                                    row.shg_deposite_amt,
                                                                    row.apy,
                                                                    row.pmsby,
                                                                    row.pmjjby,
                                                                    row.district,
                                                                );
                                                            }}
                                                        />
                                                        <DeleteIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "darkred",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                deleteUser(
                                                                    row.id
                                                                );
                                                            }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <TablePagination
                    rowsPerPageOptions={[100]}
                    component="div"
                    count={bca.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {bca.length == 0 && (
                <>
                    <div className="text-center mt-5">
                        <h1>NO DAILY REPORT FOUND</h1>
                        <p>For BCA - Please click on Add Button to add new daily report</p>
                        <p>For Admin - Please click on Refresh Button</p>
                    </div>
                    {/* <Paper
                        sx={{
                            width: "98%",
                            overflow: "hidden",
                            padding: "12px",
                        }}
                    >
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={30}
                        />
                        <Box height={40} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                    </Paper> */}
                </>
            )}
        </>
    );
}
