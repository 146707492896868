import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Skeleton from "@mui/material/Skeleton";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import GridOnIcon from '@mui/icons-material/GridOn';
import { CSVDownload } from "react-csv";

export default function DataList() {
    const baseURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    // const updateBca = useAppStore((state) => state.updateBca);
    // const bcastore = useAppStore((state) => state.bca);
    const adminEmail = useAppStore((state) => state.adminEmail);
    const token = useAppStore((state) => state.token);
    const utype = useAppStore((state) => state.utype);
    const navigate = useNavigate();
    const [isDownload, setIsDownload] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [bca, setBca] = useState('');

    const handleFilter = () => {
        // console.log('Filtered Start Date ', startDate);
        // console.log('Filtered End Date ', endDate);

        const filtered = bca.filter(item => {
            const createdAt = new Date(item.created_at);
            return createdAt >= new Date(startDate) && createdAt <= new Date(endDate);
        });

        // console.log('Filtered filtered ', filtered);

        setBca(filtered);
    };

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        getUsersCall();
    }, []);

    const getUsersCall = () => {
        if (utype === "Admin") {
            let posturl = baseURL + "bcaprofile";
            getUsers(posturl);
        }
        if (utype === "Project Head") {
            let posturl = baseURL + "bcaprofile";
            getUsers(posturl);
        }
        if (utype === "Area Coordinator") {
            let posturl = baseURL + "onlybcaprofilereport";
            getUsers(posturl);
        }
        if (utype === "District Coordinator") {
            let posturl = baseURL + "onlybcaprofilereport";
            getUsers(posturl);
        }
    }

    const getUsers = async (posturl) => {
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // updateBca(res.data);
                setBca(res.data || []);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                deleteApi(id);
            }
        });
    };

    const deleteApi = async (id) => {
        let posturl = baseURL + "bcaprofile/" + id;
        await axios
            .delete(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (res) {
                console.log(res.data);
                getUsersCall();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const filterData = (v) => {
        if (v) {
            setBca([v]);
        } else {
            getUsersCall();
        }
    };

    const editData = (
        id,
        user_id,
        name,
        phone,
        email,
        koid,
        location,
        pincode,
        district,
        ssa_name,
        ssa_code,
        base_branch,
        base_branch_code,
        base_branch_mobile,
        gender,
        dob,
        father_husband_name,
        educational_qualification,
        bank_name,
        agent_reg_id,
        aadhaar,
        pan,
        current_account,
        ro_name,
        ro_mobile_no,
        check,

    ) => {
        const data = {
            id: id,
            user_id: user_id,
            name: name,
            phone: phone,
            email: email,
            koid: koid,
            location: location,
            pincode: pincode,
            district: district,
            ssa_name: ssa_name,
            ssa_code: ssa_code,
            base_branch: base_branch,
            base_branch_code: base_branch_code,
            base_branch_mobile: base_branch_mobile,
            gender: gender,
            dob: dob,
            father_husband_name: father_husband_name,
            educational_qualification: educational_qualification,
            bank_name: bank_name,
            agent_reg_id: agent_reg_id,
            aadhaar: aadhaar,
            pan: pan,
            current_account: current_account,
            ro_name: ro_name,
            ro_mobile_no: ro_mobile_no,
            check: check,

        };
        navigate("/edit-bcaprofile", {
            state: {
                id: id,
                user_id: user_id,
                name: name,
                phone: phone,
                email: email,
                koid: koid,
                location: location,
                pincode: pincode,
                district: district,
                ssa_name: ssa_name,
                ssa_code: ssa_code,
                base_branch: base_branch,
                base_branch_code: base_branch_code,
                base_branch_mobile: base_branch_mobile,
                gender: gender,
                dob: dob,
                father_husband_name: father_husband_name,
                educational_qualification: educational_qualification,
                bank_name: bank_name,
                agent_reg_id: agent_reg_id,
                aadhaar: aadhaar,
                pan: pan,
                current_account: current_account,
                ro_name: ro_name,
                ro_mobile_no: ro_mobile_no,
                check: check,
            },
        });
    };

    const handleExport = () => {
        setIsDownload(true);
    };

    useEffect(() => {
        if (isDownload) {
            setIsDownload(false);
        }
    }, [isDownload]);

    return (
        <>
            {isDownload && (
                <>
                    <CSVDownload data={bca} target="_blank" />;
                </>
            )}
            <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                <div style={{ display: "flex" }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ padding: "20px" }}
                    >
                        BCA Profile Report
                    </Typography>
                </div>

                <Divider />
                <Box height={10} />
                <Stack direction="row" spacing={2} className="my-2 mb-2">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={bca}
                        sx={{ width: 300 }}
                        onChange={(e, v) => filterData(v)}
                        getOptionLabel={(bca) => bca.koid || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label="Search"
                            />
                        )}
                    />
                    <div className="flex justify-center">
                        <div className="mr-7 ml-4">
                            <label className="block text-gray-700 text-sm font-bold">Start Date:</label>
                            <input
                                type="date"
                                className="border rounded w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>

                        <div className="mr-7">
                            <label className="block text-gray-700 text-sm font-bold">End Date:</label>
                            <input
                                type="date"
                                className="border rounded w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>

                        <button
                            style={{ minWidth: "100px", maxHeight: "40px" }}
                            className="bg-blue-500 mt-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handleFilter}
                        >
                            Filter
                        </button>
                    </div>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    <Button
                        variant="contained"
                        endIcon={<GridOnIcon />}
                        style={{ minWidth: "100px", maxHeight: "40px", marginTop: "10px" }}
                        onClick={handleExport}
                    >
                        Export
                    </Button>
                    {/* <Button
                        variant="contained"
                        endIcon={<AddCircleIcon />}
                        style={{ minWidth: "100px", maxHeight: "40px", marginTop: "10px" }}
                        onClick={() => {
                            navigate("/add-bca");
                        }}
                    >
                        Add
                    </Button> */}
                </Stack>
                <Box height={10} />
                {bca.length > 0 && (
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Phone
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        KOID
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        SSA Code
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Base Branch
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Base Branch Code
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Registered At
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bca
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell align="left">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.phone}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.koid}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.ssa_code}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.base_branch}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.base_branch_code}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {moment(
                                                        row.created_at
                                                    ).format("MMMM Do YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack
                                                        spacing={2}
                                                        direction="row"
                                                    >
                                                        <EditIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "blue",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                editData(
                                                                    row.id,
                                                                    row.user_id,
                                                                    row.name,
                                                                    row.phone,
                                                                    row.email,
                                                                    row.koid,
                                                                    row.location,
                                                                    row.pincode,
                                                                    row.district,
                                                                    row.ssa_name,
                                                                    row.ssa_code,
                                                                    row.base_branch,
                                                                    row.base_branch_code,
                                                                    row.base_branch_mobile,
                                                                    row.gender,
                                                                    row.dob,
                                                                    row.father_husband_name,
                                                                    row.educational_qualification,
                                                                    row.bank_name,
                                                                    row.agent_reg_id,
                                                                    row.aadhaar,
                                                                    row.pan,
                                                                    row.current_account,
                                                                    row.ro_name,
                                                                    row.ro_mobile_no,
                                                                    row.check,
                                                                );
                                                            }}
                                                        />
                                                        {adminEmail === "manu@cdotbihar.org" && (
                                                            <DeleteIcon
                                                                style={{
                                                                    fontSize:
                                                                        "20px",
                                                                    color: "darkred",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    deleteUser(
                                                                        row.id
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <TablePagination
                    rowsPerPageOptions={[100]}
                    component="div"
                    count={bca.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {bca.length == 0 && (
                <>
                    <Paper
                        sx={{
                            width: "98%",
                            overflow: "hidden",
                            padding: "12px",
                        }}
                    >
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={30}
                        />
                        <Box height={40} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                    </Paper>
                </>
            )}
        </>
    );
}
