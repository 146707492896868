import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Skeleton from "@mui/material/Skeleton";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import GridOnIcon from '@mui/icons-material/GridOn';
import { CSVDownload } from "react-csv";


export default function DataList() {
    const updateIsSocialData = useAppStore((state) => state.updateIsSocialData);
    const isSocialData = useAppStore((state) => state.isSocialData);
    const baseURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    // const updateSocial = useAppStore((state) => state.updateSocial);
    // const socialstore = useAppStore((state) => state.social);
    const token = useAppStore((state) => state.token);
    const utype = useAppStore((state) => state.utype);
    const navigate = useNavigate();
    const [isDownload, setIsDownload] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [social, setSocial] = useState(isSocialData);

    const handleRefresh = () => {
        // console.log('handleRefresh');
        getUsersCall();
    };


    const handleFilter = () => {
        // console.log('Filtered Start Date ', startDate);
        // console.log('Filtered End Date ', endDate);

        const filtered = isSocialData.filter(item => {
            const createdAt = new Date(item.created_at);
            return createdAt >= new Date(startDate) && createdAt <= new Date(endDate);
        });

        // console.log('Filtered filtered ', filtered);

        setSocial(filtered);
    };

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        getUsersCall();
    }, []);

    const getUsersCall = () => {
        if (utype === "Admin" || utype === "Project Head") {
            const posturl = `${baseURL}social`;
            getUsers(posturl);
        } else if (utype === "Area Coordinator" || utype === "District Coordinator") {
            const posturl = `${baseURL}onlysocial`;
            getUsers(posturl);
        }
        else if (utype === "BC Agent") {
            let posturl = baseURL + "mysocial";
            getUsers(posturl);
        }
    };


    const getPaginatedUsers = async (lastPage) => {
        const accumulatedData = [];

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        const fetchPageData = async (page) => {
            const posturl = `${baseURL}social?page=${page}`;
            return axios
                .get(posturl, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    console.log('bcadata ', res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("kcheckpost" + err); // return 429
                    return null;
                });
        };

        for (let i = 2; i <= lastPage; i++) {
            await delay(1000); // Wait for 1 second between requests
            const pageData = await fetchPageData(i);
            if (pageData) {
                accumulatedData.push(...pageData.data);
            }
        }

        // Update the state after collecting all data
        setSocial(accumulatedData);
        updateIsSocialData(accumulatedData);
    };

    const getUsers = async (posturl) => {
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // updateSocial(res.data);
                // setSocial(res.data.data);
                // updateIsSocialData(res.data.data);
                setSocial(res.data.data || []); 
                updateIsSocialData(res.data.data || []);
                let lastPage = res.data.last_page;
                if (lastPage > 1) {
                    if (utype === "Admin" || utype === "Project Head") {
                        getPaginatedUsers(lastPage);
                    }
                }
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                deleteApi(id);
            }
        });
    };

    const deleteApi = async (id) => {
        let posturl = baseURL + "social/" + id;
        await axios
            .delete(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (res) {
                console.log(res.data);
                getUsersCall();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const filterData = (v) => {
        if (v) {
            setSocial([v]);
        } else {
            setSocial(isSocialData);
        }
    };

    const editData = (
        id,
        user_id,
        koid,
        location,
        pincode,
        scheme,
        form_submission_date,
        name_of_bank,
        branch_code,
        branch_name,
        customer_account,
        customer_name,
        apy,
        pmsby,
        pmjjby,
        district,

    ) => {
        const data = {
            id: id,
            user_id: user_id,
            koid: koid,
            location: location,
            pincode: pincode,
            scheme: scheme,
            form_submission_date: form_submission_date,
            name_of_bank: name_of_bank,
            branch_code: branch_code,
            branch_name: branch_name,
            customer_account: customer_account,
            customer_name: customer_name,
            apy: apy,
            pmsby: pmsby,
            pmjjby: pmjjby,
            district: district,

        };
        navigate("/edit-social", {
            state: {
                id: id,
                user_id: user_id,
                koid: koid,
                location: location,
                pincode: pincode,
                scheme: scheme,
                form_submission_date: form_submission_date,
                name_of_bank: name_of_bank,
                branch_code: branch_code,
                branch_name: branch_name,
                customer_account: customer_account,
                customer_name: customer_name,
                apy: apy,
                pmsby: pmsby,
                pmjjby: pmjjby,
                district: district,

            },
        });
    };

    const handleExport = () => {
        setIsDownload(true);
    };

    useEffect(() => {
        if (isDownload) {
            setIsDownload(false);
        }
    }, [isDownload]);

    return (
        <>
            {isDownload && (
                <>
                    <CSVDownload data={social} target="_blank" />;
                </>
            )}
            <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                <div style={{ display: "flex" }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ padding: "20px" }}
                    >
                        Social Security Scheme Report
                    </Typography>
                </div>

                <Divider />
                <Box height={10} />
                <Stack direction="row" spacing={2} className="my-2 mb-2">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={social}
                        sx={{ width: 300 }}
                        onChange={(e, v) => filterData(v)}
                        getOptionLabel={(social) => social.koid || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label="Search"
                            />
                        )}
                    />
                    <div className="flex justify-center">
                        <div className="mr-7 ml-4">
                            <label className="block text-gray-700 text-sm font-bold">Start Date:</label>
                            <input
                                type="date"
                                className="border rounded w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>

                        <div className="mr-7">
                            <label className="block text-gray-700 text-sm font-bold">End Date:</label>
                            <input
                                type="date"
                                className="border rounded w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>

                        <button
                            style={{ minWidth: "100px", maxHeight: "40px" }}
                            className="bg-blue-500 mt-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handleFilter}
                        >
                            Filter
                        </button>
                    </div>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    ></Typography>

                    <Button
                        variant="contained"
                        endIcon={<GridOnIcon />}
                        style={{ minWidth: "100px", maxHeight: "40px", marginTop: "10px" }}
                        onClick={handleExport}
                    >
                        Export
                    </Button>
                    {utype == "BC Agent" && (
                        <>
                            <Button
                                variant="contained"
                                endIcon={<AddCircleIcon />}
                                style={{ minWidth: "100px", maxHeight: "40px", marginTop: "10px" }}
                                onClick={() => {
                                    navigate("/add-social");
                                }}
                            >
                                Add
                            </Button>
                        </>
                    )}
                </Stack>
                <Box height={10} />
                {social && social.length > 0 && (
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        KOID
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Scheme
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Form Submission Date
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Bank Name
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Branch Code
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Customer Account
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Created At
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {social
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell align="left">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.koid}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.scheme}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {moment(
                                                        row.form_submission_date
                                                    ).format("MMMM Do YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.name_of_bank}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.branch_code}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.customer_account}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {moment(
                                                        row.created_at
                                                    ).format("MMMM Do YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack
                                                        spacing={2}
                                                        direction="row"
                                                    >
                                                        <EditIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "blue",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                editData(
                                                                    row.id,
                                                                    row.user_id,
                                                                    row.koid,
                                                                    row.location,
                                                                    row.pincode,
                                                                    row.scheme,
                                                                    row.form_submission_date,
                                                                    row.name_of_bank,
                                                                    row.branch_code,
                                                                    row.branch_name,
                                                                    row.customer_account,
                                                                    row.customer_name,
                                                                    row.apy,
                                                                    row.pmsby,
                                                                    row.pmjjby,
                                                                    row.district,
                                                                );
                                                            }}
                                                        />
                                                        <DeleteIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "darkred",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                deleteUser(
                                                                    row.id
                                                                );
                                                            }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <TablePagination
                    rowsPerPageOptions={[100]}
                    component="div"
                    count={social?.length || 0}
                    rowsPerPage={100}
                    page={page}
                    onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {social.length == 0 && (
                <>
                    <div className="text-center mt-5">
                        <h1>NO SOCIAL REPORT FOUND</h1>
                        <p>For BCA - Please click on Add Button to add new daily report</p>
                        <p>For Admin - Please click on Refresh Button</p>
                    </div>
                    {/* <Paper
                     sx={{
                         width: "98%",
                         overflow: "hidden",
                         padding: "12px",
                     }}
                 >
                     <Box height={20} />
                     <Skeleton
                         variant="rectangular"
                         width={"100%"}
                         height={30}
                     />
                     <Box height={40} />
                     <Skeleton
                         variant="rectangular"
                         width={"100%"}
                         height={60}
                     />
                     <Box height={20} />
                     <Skeleton
                         variant="rectangular"
                         width={"100%"}
                         height={60}
                     />
                     <Box height={20} />
                     <Skeleton
                         variant="rectangular"
                         width={"100%"}
                         height={60}
                     />
                     <Box height={20} />
                     <Skeleton
                         variant="rectangular"
                         width={"100%"}
                         height={60}
                     />
                     <Box height={20} />
                     <Skeleton
                         variant="rectangular"
                         width={"100%"}
                         height={60}
                     />
                     <Box height={20} />
                 </Paper> */}
                </>
            )}
        </>
    );
}
